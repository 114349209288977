// initial state
import ApplicationApi from "../../api/ApplicationApi";
import Cookie from 'js-cookie';
import { getBrowserLanguage } from "../../utils/browser";

const SHOW_COOKIE_AGREEMENT = 'fitelio/showCookieAgreement';
const IS_SHOW_DISABLED_PROJECTS = localStorage.getItem('preview') === '1';

const state = {
    loading: true,
    firstLoadCompleted: process.env.NODE_ENV !== 'production', // false
    settings: {},
    projects: [],
    reviews: [],
    language: Cookie.get('language') || getBrowserLanguage(),
    isShowCookieAgreement: !Cookie.get(SHOW_COOKIE_AGREEMENT)
};

// getters
const getters = {
    translations: state => state.settings.translations,
    languages: state => state.settings.languages,
    config: state => state.settings.config,
    projects: state => state.projects.filter((project => project.is_active || IS_SHOW_DISABLED_PROJECTS)),
    reviews: state => state.reviews,
    isShowCookieAgreement: state => state.isShowCookieAgreement,
    // PAGES
    main: state => state.settings.main,
    about: state => state.settings.about,
    contactPage: state => state.settings.contact_page,
    projectsPage: state => state.settings.all_projects,
    dominosCases: state => state.settings.dominos_cases,
    language: state => state.language,
    featureProjects: state => state.projects.filter(project => project.featured)
};

//TODO add lang changing
// mutations
const mutations = {
    setLoading(state, payload) {
        if (state.firstLoadCompleted) {
            state.loading = payload;
        }
    },
    firstLoadComplete(state){
        state.firstLoadCompleted = true;
        state.loading = false;
    },

    setSettings(state, payload){
        state.settings = payload
    },

    setProjects(state, payload){
        state.projects = payload
    },

    setReviews(state, payload){
        state.reviews = payload
    },

    setLanguage(state, payload){
        Cookie.set('language', payload, { expires: 365, path: '/' });
        state.language = payload
    },

    hideCookieAgreement(state){
        Cookie.set(SHOW_COOKIE_AGREEMENT, false);
        state.isShowCookieAgreement = false
    }
};

// actions async tasks
const actions = {
    async getSettings({ commit }){
        const { data } = await ApplicationApi.getSettings();
        commit('setSettings', data);
        commit('setLoading', false);
        return data;
    },

    async getProjects({ commit }){
        const { data } = await ApplicationApi.getProjects();
        commit('setProjects', data);
        return data;
    },

    async getReviews({ commit }){
        const { data } = await ApplicationApi.getReviews();
        commit('setReviews', data);
        return data;
    },

    async setLanguage({ commit }, lang){
        commit('setLanguage', lang);
        const { data: settings } = await ApplicationApi.getSettings();
        commit('setSettings', settings);
        const { data: projects } = await ApplicationApi.getProjects();
        commit('setProjects', projects);
        const { data: reviews } = await ApplicationApi.getReviews();
        commit('setReviews', reviews);
    }
};

export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
