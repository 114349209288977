/**
 * @module config/responsive
 */

/**
 * The width of the window at which the mobile version begins
 * @type {number}
 */
export const MOBILE_WIDTH = 768;
export const DESKTOP_WIDTH = 1248;

/**
 * Enum with type of devices
 * @type {{DESKTOP: string, TABLET: string, MOBILE: string}}
 */
export const DEVICE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

/**
 * Return type of device by width
 * @param width
 * @returns {string}
 */
export function getDeviceByWidth(width) {
  if (width <= MOBILE_WIDTH) {
    return DEVICE.MOBILE ;
  }
  if (width > MOBILE_WIDTH && width < DESKTOP_WIDTH) {
    return DEVICE.TABLET ;
  }
  if (width >= DESKTOP_WIDTH) {
    return DEVICE.DESKTOP ;
  }
  return DEVICE.DESKTOP
}
