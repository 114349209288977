<template>
    <form class="contact-form">

        <div class="contact-form__content-row">
            <Input
                    type="text"
                    :value="fieldData.name"
                    @input="(e) => handleChange(e, 'name')"
                    :placeholder="translations.contact_form.contacts_name_placeholder"
            />
            <div class="contact-form__error" v-show="errors.name">
                {{translations.contact_form.contacts_name_error}}
            </div>
        </div>

        <div class="contact-form__content-row">
            <MaskedInput
                    key="phone"
                    :mask="'\+11(111)111-11-11'"
                    :value="fieldData.phone"
                    @input="(e) => handleChange(e, 'phone')"
                    :placeholder="translations.contact_form.contacts_phone_placeholder"/>
            <div class="contact-form__error"
                 v-show="errors.phone">{{translations.contact_form.contacts_phone_error}}
            </div>
        </div>

        <div class="contact-form__content-row">
            <Input
                    type="email"
                    :value="fieldData.email"
                    @input="(e) => handleChange(e, 'email')"
                    :placeholder="translations.contact_form.contacts_email_placeholder"
            />
            <div class="contact-form__error"
                 v-show="errors.email">{{translations.contact_form.contacts_email_error}}
            </div>
        </div>

        <div class="contact-form__content-row">
            <Input
                    type="text"
                    :value="fieldData.message"
                    @input="(e) => handleChange(e, 'message')"
                    :placeholder="translations.contact_form.contacts_message_placeholder"
            />
        </div>
        <div class="contact-form__content-row contact-form__content-row_agreement">
            <CheckBox
                    @change="(e) => handleChange(e, 'agreement')"
                    :checked="fieldData.agreement"
                    :label="translations.privacy_label"
            />
        </div>


        <div class="contact-form__content-row contact-form__content-row_right">
            <Button
                    class="contact-form__submit"
                    :onClick="sendFormData"
                    :disabled="!fieldData.agreement"
            >
                {{translations.contact_form.contacts_button_text}}
            </Button>
        </div>
    </form>
</template>
<script>

    import Button from "./button";
    import {Input, MaskedInput, CheckBox} from "./input";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'ContactForm',
        components: {
            Button,
            Input,
            MaskedInput,
            CheckBox
        },
        props: {
            title: {
                type: String
            },
            onFormSubmit:{
                type: Function,
                default: () => {}
            }
        },
        computed: {
            ...mapGetters([
                'translations',
                'fieldData',
                'errors',
            ]),

        },
        methods: {
            ...mapActions([
                'postContact',
                'changeField',
            ]),
            ...mapMutations([
                'setModalData',
            ]),

            handleChange(value, field) {
                this.changeField({field, value});
            },
            sendFormData() {
                this.postContact()
                    .then((response) => {
                        this.setModalData(response);
                        this.onFormSubmit(response)
                    });
            },
        },
    }
</script>


<style lang="sass">
    .contact-form
        height: 500px
        width: 100%
        margin-bottom: 70px

        &__title
            font-weight: bold
            font-size: 40px
            line-height: 50px
            letter-spacing: 0.03em

        &__content-row
            width: 100%
            margin-bottom: 10px
            position: relative
            display: flex
            flex-direction: column

            &_center
                align-items: center

            &_right
                align-items: flex-end

            &_agreement
                padding-top: 15px

        &__input
            height: 60px
            background-color: transparent
            width: 100%
            outline: none
            box-shadow: none
            border-top: none
            border-left: none
            border-right: none
            border-bottom: 2px solid #4F4F4F
            box-sizing: border-box
            border-radius: 0
            -webkit-appearance: none
            font-weight: bold
            font-size: 17px
            line-height: 40px
            color: #F2F2F2
            letter-spacing: 0.03em

            &:focus
                border-color: #F2F2F2

            &::placeholder
                letter-spacing: 0.03em
                font-weight: bold
                font-size: 20px
                line-height: 40px

        &__error
            position: relative
            right: 0
            bottom: -5px
            font-size: 11px
            text-align: right

        &__submit
            margin-top: 60px

</style>
