<template>
    <div :class="{'header-component': true,
  'header-component-lit-show': showLittleHeader}">

        <div class="header-component__content">
            <div class="header-component__logo"
                 @click="goHome">
                <Logo/>
            </div>

            <div class="header-component__lang">
                <div :class="{'header-component__lang-item': true, 'active-lang': item.code === language}"
                     v-for="item of languages"
                     :key="item.code"
                     @click="changeLanguage(item.code)">
                    <span>{{item.name}}</span>
                </div>
            </div>

            <div class="header-component__links" v-if="translations.link_titles">
                <router-link
                        :class="{'header-component__links-item': true, 'header-component__links-item_active': activePage === 'about'}"
                        to="/about">{{translations.link_titles.about}}
                </router-link>
                <router-link
                        :class="{'header-component__links-item': true, 'header-component__links-item_active': activePage === 'projects'}"
                        to="/projects">{{translations.link_titles.projects}}
                </router-link>
                <!--              <router-link class="header-component__links-item" to="/team">{{link_titles.blog}}</router-link>-->
                <!--              <router-link class="header-component__links-item" to="/team">{{link_titles.team}}</router-link>-->
                <router-link
                        :class="{'header-component__links-item': true, 'header-component__links-item_active': activePage === 'contact'}"
                        to="/contact">{{translations.link_titles.contacts}}
                </router-link>
            </div>
            <div class="header-component__divider"></div>
        </div>
        <!--        <div :class="{'header-component-lit': littleHeader,-->
        <!--                  'header-component-lit-show': showLittleHeader}"-->
        <!--             v-if="littleHeader">-->
        <!--            <div class="header-component__logo" @click="goHome">-->
        <!--                <Logo/>-->
        <!--            </div>-->
        <!--            <div class="header-component__links" v-if="linkTitles">-->
        <!--                <router-link :class="{'header-component__links-item': true,-->
        <!--                            'router-link-exact-active': $route.name === 'home'}"-->
        <!--                             to="/projects">{{linkTitles.projects}}-->
        <!--                </router-link>-->
        <!--                <router-link class="header-component__links-item" to="/team">{{linkTitles.team}}</router-link>-->
        <!--                <router-link class="header-component__links-item" to="/contact">{{linkTitles.contacts}}</router-link>-->
        <!--            </div>-->
        <!--            <div class="header-component__lang">-->
        <!--                <div :class="{'header-component__lang-item': true, 'active-lang': item.code === activeLang}"-->
        <!--                     v-for="item of languages"-->
        <!--                     :key="item.code"-->
        <!--                     @click="changeLanguage(item.code)">-->
        <!--                    <span>{{item.name}}</span>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from "vuex";

    import Logo from '@/components/common/Logo'

    let dividerShowState = null;

    export default {
        name: 'Header',
        components: {
            Logo,
        },
        props: {
            activeLang: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                littleHeader: false,
                showLittleHeader: false,
                transitionLittleHeader: false,
                prevScrollTop: 0,
            };
        },
        computed: {
            ...mapState({
                scrollOffset: state => state.ui.scrollOffset,
            }),
            ...mapGetters([
                'languages',
                'translations',
                'language'

            ]),
            activePage: function () {
                return this.$route.name;
            },

            showDivider: function () {
                return this.$route.name !== 'home';
            },
        },
        watch: {
            showDivider: function (show) {
                this.toggleDivider(show)
            },
            scrollOffset(newValue) {
                if (this.$route.name === 'home') {
                    if (newValue === 0) {
                        this.toggleDivider(false)
                    } else {
                        this.toggleDivider(true)
                    }
                }
            }
        },
        created() {
            window.onscroll = this.scrollPage;
        },
        mounted() {
            this
                .$anime
                .timeline()
                .add({
                    targets: '.header-component',
                    opacity: [0, 1],
                    easing: 'linear',
                    duration: 750,
                    delay: 2000,
                });
            if (this.showDivider) {
                this.toggleDivider(true)
            }
        },
        methods: {
            ...mapActions([
                'setLanguage'
            ]),
            changeLanguage(lang) {
                this.setLanguage(lang)
            },

            goHome() {
                if (this.$router.currentRoute.path !== '/') {
                    this.$router.push({ name: 'home' });
                }
            },
            scrollPage() {
                this.littleHeader = window.scrollY > 160;
                if (window.scrollY > this.prevScrollTop || window.scrollY < 160) {
                    this.prevScrollTop = window.scrollY;
                    this.showLittleHeader = false;
                } else {
                    this.showLittleHeader = true;
                    this.prevScrollTop = window.scrollY;
                }
            },
            toggleDivider(show) {
                if (dividerShowState !== show) {
                    const options = show ? [0, '100%'] : ['100%', 0];
                    this
                        .$anime
                        .timeline()
                        .add({
                            targets: '.header-component__divider',
                            width: options,
                            easing: "easeInOutQuad",
                            delay: 20
                        });
                    dividerShowState = show
                }
            }


        },

    }
</script>

<style scoped lang="sass">

    .header-component, .header-component-lit
        @include fxc
        height: $header-height
        width: 100%
        position: fixed
        top: 0
        z-index: 5
        background-color: $bgc

        &__content
            @include grid(12, 1, 0, 100%)
            height: 100%
            width: 100%
            max-width: $max-content-width
            padding: 0 80px
            align-items: center

        &__logo
            @include fxc
            height: 100%
            align-items: flex-end
            padding-bottom: 20px
            grid-column-start: 1
            grid-column-end: 2

        &__lang
            @include fxc
            height: 100%
            align-items: flex-end
            padding-bottom: 50px
            grid-column-start: 3
            grid-column-end: 4
            font-size: 14px
            font-weight: bold

            &-item
                cursor: pointer
                color: $darken-font-color
                @include transition(color)

                &:first-child
                    margin-right: 20px

                &:hover
                    color: $font-color

            .active-lang
                color: $font-color

        &-lit
            top: -60px
            position: fixed
            width: calc(100% - 140px)
            height: 60px

        &-show
            transition: all .3s
            top: 0

            &-transition
                top: -60px

        &__links
            @include fxc
            height: 100%
            align-items: flex-end
            padding-bottom: 50px
            justify-content: flex-end
            grid-column-start: 8
            grid-column-end: 13
            @include mq(tablet)
                grid-column-start: 5

            &-item
                margin-right: 20px
                font-size: 14px
                line-height: 140%
                font-weight: bold
                text-decoration: none
                color: $darken-font-color
                @include transition(color)

                &:hover
                    color: $font-color

                &_active
                    color: $font-color

        &__divider
            width: 0
            grid-column-start: 1
            grid-column-end: 13
            border-bottom: $border

</style>
