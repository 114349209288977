<template>
    <div class="about">
        <div class="about__content">

            <PageHeader
                    class="about__header"
                    :header="about.title"
                    :textSize="textSize"

            />

            <div class="about__right-column">

                <div class="about__about-text">
                    <p
                            v-for="(item, index) in about.description"
                            v-bind:key="index"
                    >
                        {{item}}<br/><br/>
                    </p>
                </div>
            </div>

            <h2 class="about__subtitle">
                {{about.why_fitelio.title}}
            </h2>

            <div class="about__reasons">
                <template
                        v-for="(item, index) in about.why_fitelio.list"
                >
                    <div v-bind:key="index" class="about__text-block">

                        <h1 class="about__about-header">
                            {{item.title}}
                        </h1>
                        <p class="about__about-text">
                            {{item.description}}
                        </p>
                    </div>
                </template>
            </div>

            <Banner
                    class="about__banner"
                    :linkTo="{name: 'project-detail', params: {id: 'dominos'}}"
                    :text="this.main.dominos_link_text"
                    :btnText="this.main.dominos_link_button"
            />

            <GetInTouch
                    :onFormSubmit="onSubmitForm"
                    class="about__contact"
            />

        </div>
    </div>

</template>

<script>

    import GetInTouch from "@/components/home/GetInTouch";
    import Banner from "@/components/common/Banner";
    import PageHeader, { TEXT_SIZES } from "@/components/common/PageHeader";
    import * as responsiveConf from '@/config/responsive'
    import { mapGetters } from "vuex";

    export default {
        name: 'About',
        components: {
            GetInTouch,
            Banner,
            PageHeader
        },
        props: {
            settings: {
                type: Object,
                default: () => {
                },
            },
            team: {
                type: Array,
                default: () => [],
            },
            mobile: {
                type: Boolean,
                default: false,
            },
            categories: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            ...mapGetters([
                'about',
                'main',
                'device'
            ]),
            textSize: function() {
                return this.device === responsiveConf.DEVICE.MOBILE ? TEXT_SIZES.MEDIUM : TEXT_SIZES.BIG
            }
        },
        mounted() {
            document.title = this.about.meta_title;
            document.querySelector('meta[name="description"]').setAttribute("content", this.about.meta_description);
        },
        methods: {
            onSubmitForm: function() {
                window.dataLayer.push({
                    'eventCategory': 'ContactForm',
                    'eventAction': 'Click',
                    'eventLabel': 'contact_form_about_page_success',
                    'event': 'contact_form_about_page_success'
                });
            }
        },
    }
</script>

<style lang="sass">
    .about
        padding-bottom: 50px

        &__content
            @include grid(12, 8, 10)

        &__header
            grid-column-start: 3
            grid-column-end: 11
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

        &__subtitle
            grid-column-start: 3
            grid-column-end: 11
            font-weight: 500
            font-size: 21px
            line-height: 36px
            margin: 25px 0
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

        &__contact
            grid-column-start: 1
            grid-column-end: 13

        &__text-block
            margin-bottom: 80px
            @include mq(phone)
                margin-bottom: 20px

        &__about-header
            display: flex
            align-items: center
            font-weight: bold
            font-size: 28px
            line-height: 40px
            margin: 20px 0

        &__about-text
            line-height: 28px
            font-size: 17px
            font-weight: 300
            letter-spacing: 0.01em


        &__reasons
            grid-column-start: 3
            grid-column-end: 11
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: 10px
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13
            @include mq(phone)
                grid-template-columns: repeat(1, 1fr)

        &__right-column
            grid-column-start: 7
            grid-column-end: 11
            @include mq(tablet)
                grid-column-end: 13
            @include mq(phone)
                grid-column-start: 1
                grid-column-end: 13

        &__banner
            grid-column-start: 3
            grid-column-end: 11
            margin: 60px 0
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

</style>
