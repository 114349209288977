<template>
    <div id="app" :class="{'app-component': true,
                          'app-component-mob': mobileWidth,
                          'app-component-contact': $route.name === 'contact'}">

        <Loader v-if="loading"/>
        <template v-else>
            <Header v-if="!mobileWidth"/>
            <HeaderMob v-if="mobileWidth"
                       @toggleMenu="toggleScroll"
            />
            <div class="app-component__content">
                <transition name="slide-fade" mode="out-in">
                    <router-view/>
                </transition>
            </div>
            <Footer :mobView="mobileWidth"/>

            <CookieAgreementModal
                    v-if="isShowCookieAgreement"
                    :onAgreeClick="this.hideCookieAgreement"/>

            <ThanksModal
                    :show="showModal"
            />

        </template>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
    import debounce from "debounce";

    import Header from '@/components/header/Header'
    import HeaderMob from '@/components/header/HeaderMob'
    import Loader from '@/components/loader/Loader'
    import Footer from '@/components/footer/Footer'
    import ThanksModal from '@/components/modal/Thanks'
    import CookieAgreementModal from '@/components/modal/CookieAgreement'

    export default {
        name: 'app',
        components: {
            Header,
            HeaderMob,
            Loader,
            Footer,
            ThanksModal,
            CookieAgreementModal
        },
        computed: {
            ...mapState({
                loading: state => state.application.loading,
            }),
            ...mapGetters([
                'showModal',
                'isShowCookieAgreement'
            ]),

        },
        data() {
            return {
                mobileWidth: window.innerWidth < 760,
            }
        },
        watch:{
            $route (){
                document.querySelector('link[rel="canonical"]').setAttribute("href", window.location.href);
            }
        },
        methods: {
            ...mapActions({
                getSettingsAPI: 'getSettings',
                postContactsAPI: 'postContact',
                getProjectsAPI: 'getProjects',
                getReviewsAPI: 'getReviews'
            }),
            ...mapMutations([
                'setLoading',
                'updateWindowWidth',
                'updateScrollOffset',
                'hideCookieAgreement'
            ]),
            debounceResize:
                debounce(function () {
                    this.handleResize();
                }, 1000),
            handleResize() {
                // TODO REMOVE this.mobileWidth use from store
                this.mobileWidth = window.innerWidth < 760;
                this.updateWindowWidth(window.innerWidth);
            },
            debounceScroll: debounce(function () {
                this.handleScroll();
            }, 10),
            handleScroll() {
                this.updateScrollOffset(window.pageYOffset);
            },
            toggleScroll(status) {
                if (status) {
                    window.addEventListener("wheel", this.scrollOff, { passive: false });
                } else {
                    window.removeEventListener("wheel", this.scrollOff, { passive: false });
                }
            },
            scrollOff(event) {
                return event.preventDefault()
            }
        },
        mounted() {
            Promise.all([
                this.getSettingsAPI(),
                this.getProjectsAPI(),
                this.getReviewsAPI()
            ]);
            document.querySelector('link[rel="canonical"]').setAttribute("href", window.location.href);
            this.updateWindowWidth(window.innerWidth);
            this.updateScrollOffset(window.pageYOffset);
            window.addEventListener('resize', this.debounceResize, { passive: true });
            window.addEventListener('scroll', this.debounceScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.debounceScroll);
            window.removeEventListener('resize', this.debounceResize);
        },
    }
</script>

<style lang="sass">

    @import "./sass/reset"
    @import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700&display=swap&subset=cyrillic')


    .slide-fade-enter-active
        transition: all .3s ease

    .slide-fade-enter, .slide-fade-leave
        transform: translateX(200px)
        opacity: 0

    *
        font-family: 'Montserrat', sans-serif

    html, body
        background-color: $bgc


    #app
        width: 100%
        text-align: start
        color: $font-color
        background-color: $bgc

    .app-component
        box-sizing: border-box
        margin: 0 auto
        min-height: 100vh

        &__content
            max-width: $max-content-width
            padding: $header-height 80px 0 80px
            margin: 0 auto

        @include mq(phone)
            padding: 0 20px
            &__content
                padding: 0

</style>
