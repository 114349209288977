<template>
    <Modal class="modal-cookie">

        <div class="modal-cookie__content">
            <div class="modal-cookie__text-block">
                <p v-html="this.translations.cookie_text"></p>

            </div>
            <button
                    @click="onAgreeClick"
                    class="modal-cookie__button"
            >
                {{this.translations.cookie_button}}
            </button>

            </div>
    </Modal>
</template>

<script>
    import { mapGetters } from "vuex";

    import Modal from "@/components/modal/Modal";

    export default {
        name: 'CookieAgreement',
        components: {
            Modal
        },
        props: {
            onAgreeClick: {
                type: Function,
                default: () => {}
            },
        },
        computed: {
            ...mapGetters([
                'translations'
            ])
        },
        methods: {
        },
        mounted() {
            this
                .$anime
                .timeline()
                .add({
                    targets: '.modal-cookie',
                    opacity: [0, 1],
                    easing: 'linear',
                    duration: 750,
                    delay: 2000,
                });
        },
    }

</script>

<style lang="sass">
    .modal-cookie
        @include grid(12, 1)

        &__content
            color: $bgc
            grid-column-start: 3
            grid-column-end: 11
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13
                margin: 10px
            background-color: $light-panel-color
            position: absolute
            bottom: 0
            z-index: 15


        &__text-block
            padding: 20px
            font-size: 18px
            line-height: 28px

        &__button
            border: none
            height: 50px
            width: 100%
            border-top: 4px solid $bgc
            border-bottom: 4px solid $bgc
            background: transparent
            font-weight: bold
            font-size: 21px
            &:hover
                cursor: pointer

</style>