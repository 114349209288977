/**
 * @module utils/axios
 */

import axios from 'axios';
import Cookie from "js-cookie";
import { getBrowserLanguage } from "./browser";

// Default request headers
const defaultHeaders = {
    'Accept-Language': 'en',
};

/**
 * Generate headers for request
 * @return {Object}
 */
function getHeaders() {
    const headers = {
        ...defaultHeaders,
        'Accept-Language': Cookie.get('language') || getBrowserLanguage(),
        'Accept': 'application/json',
        'Content-type': 'application/json;charset=utf-8'
    };
    return headers;
}

/**
 * Generate request config
 * @param {Object} cfg
 * @return {Object}
 */
function getConfig(cfg = {}) {
    return {
        ...cfg,
        headers: {
            ...cfg.headers,
            ...getHeaders(),
        }
    };
}

/**
 * Request handler
 * @param {Object} cfg
 * @return {Promise<any>}
 */
function request(cfg = {}) {
    return new Promise((res, rej) => {
        axios(getConfig(cfg))
            .then(result => res(result))
            .catch((e) => {
                rej(e);
            });
    });
}

/**
 * Make GET request
 * @param {string} url
 * @param {Object} query
 * @return {Promise<any>}
 */
export function get(url, query = {}) {
    return request({
        method: 'get',
        url,
        params: query,
    });
}

/**
 * Make POST request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @return {Promise<any>}
 */
export function post(url, data = {}, query = {}) {
    return request({
        method: 'post',
        url,
        params: query,
        data,
    });
}

/**
 * Make PUT request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @return {Promise<any>}
 */
export function put(url, data = {}, query = {}) {
    return request({
        method: 'put',
        url,
        params: query,
        data,
    });
}

/**
 * Make DELETE request
 * @param {string} url
 * @param {Object} data
 * @param {Object} query
 * @return {Promise<any>}
 */
export function del(url, data = {}, query = {}) {
    return request({
        method: 'delete',
        url,
        params: query,
        data,
    });
}
