<template>
    <div class="modal">
        <slot/>
        <div class="modal__bcg"/>

    </div>
</template>

<script>

    export default {
        name: 'Modal',
    }

</script>

<style lang="sass">
    .modal
        z-index: 10
        flex-direction: column
        height: 100%
        width: 100%
        position: fixed
        top: 0
        left: 0
        box-sizing: border-box
        @include mq(phone)

        &__bcg
            position: fixed
            top: 0
            left: 0
            height: 100%
            width: 100%
            background-color: $bgc
            opacity: 0.5

</style>