<template>
    <div class="accordion">
        <div :class="{'accordion-content': true, 'accordion-content_open': isOpen}">
            <div
                    class="accordion-content__title"
                    @click="toggle"
            >
                <p class="accordion-content__title-text">{{title}}</p>
                <div class="accordion-content__title-icon">
                    <div v-bind:class="{ 'accordion-content__title-sign': true, 'accordion-content__title-sign_open': isOpen }"></div>
                </div>
            </div>
            <transition name="accordion"
                        v-on:before-enter="beforeEnter" v-on:enter="enter"
                        v-on:before-leave="beforeLeave" v-on:leave="leave">
                >
                <div v-if="isOpen" class="accordion-content__body">
                    <slot></slot>
                </div>
            </transition>
        </div>
        <div :class="['accordion__border', 'accordion__border_top', isOpen && 'accordion__border_highlight']" ></div>
        <div :class="['accordion__border', 'accordion__border_bottom' , isOpen && 'accordion__border_highlight']" ></div>

    </div>
</template>

<script>

    export default {
        name: 'Accordion',
        components: {},
        props: {
            open: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            }
        },
        data: function () {
            return {
                isOpen: this.open
            }
        },
        methods: {
            toggle: function () {
                return this.isOpen = !this.isOpen;
            },
            beforeEnter: function (el) {
                el.style.height = '0';
            },
            enter: function (el) {
                el.style.height = el.scrollHeight + 'px';
            },
            beforeLeave: function (el) {
                el.style.height = el.scrollHeight + 'px';
            },
            leave: function (el) {
                el.style.height = '0';
            }
        }
    }
</script>

<style lang="sass">

    .accordion
        position: relative
        height: fit-content
        margin: -1px
        color: $font-color

        &__border
            position: absolute
            height: 2px
            width: 0
            background-color: $font-color
            @include transition(width)
            &_top
                top: 0
            &_bottom
                bottom: -1px
            &_highlight
                width: 100%
                z-index: 1

        &:hover
            z-index: 1
            .accordion__border
                width: 100%

    .accordion-content
        position: relative
        height: fit-content
        box-sizing: content-box
        width: 100%
        border-top: $border
        border-bottom: $border
        cursor: pointer
        background-color: $bgc
        @include transition(all)

        &_open
            background-color: $panel-color
            .accordion__border
                width: 100%

        &__title
            @include fxc
            min-height: 90px
            width: 100%
            justify-content: space-between
            padding-left: 20px
            box-sizing: border-box
            font-size: 28px
            line-height: 40px
            letter-spacing: 0.03em
            font-weight: bold
            transition: background-color 0.3s ease

            &:hover


            &-text
                padding: 20px 0
                user-select: none

            &-icon
                @include fxc
                height: 100%
                width: 65px
                min-width: 65px

            &-sign
                position: relative
                width: 14px
                height: 14px

                &:before,
                &:after
                    content: ""
                    position: absolute
                    background-color: white
                    transition: transform 0.25s ease-out

                /* Vertical line */

                &:before
                    top: 0
                    left: 50%
                    width: 2px
                    height: 100%
                    margin-left: -1px

                /* horizontal line */

                &:after
                    top: 50%
                    left: 0
                    width: 100%
                    height: 2px
                    margin-top: -1px

                &_open
                    &:before
                        transform: rotate(90deg)


        &__body
            padding: 0 20px 20px 20px
            font-weight: 300
            font-size: 17px
            overflow: hidden
            line-height: 28px
            @include transition(height)
</style>
