<template>
    <div class="masked-input">
        <masked-input
                class="masked-input__component"
                :value="value"
                :mask="mask"
                :placeholder="placeholder"
                @input="handleChangeMaskInput"
                @focus.native="toggleDivider(true)"
                @blur.native="toggleDivider(false)"
        />
        <div ref="border" class="masked-input__animated-border"></div>
    </div>
</template>

<script>
    import maskedInput from 'vue-masked-input'

    export default {
        name: 'MaskedInput',
        components: {
            maskedInput,
        },
        props: {
            value: {
                type: String,
            },
            mask: {
                type: String,
                default: ""
            },
            placeholder: {
                type: String,
                default: ""
            },
        },
        methods: {
            handleChangeMaskInput(value) {
                if (value) {
                    this.$emit('input', value)
                }
            },
            toggleDivider(show) {
                const options = show ? [0, '100%'] : ['100%', 0];
                this
                    .$anime
                    .timeline()
                    .add({
                        targets: this.$refs.border,
                        width: options,
                        easing: "easeInOutQuad",
                    });
            }
        }
    }
</script>

<style scoped lang="sass">

    .masked-input
        width: 100%
        position: relative


        &__component
            padding: 0 5px
            height: 60px
            width: 100%
            outline: none
            border: none
            border-bottom: 2px solid $border-color
            background-color: $bgc!important

            box-sizing: border-box
            border-radius: 0
            -webkit-appearance: none
            font-weight: bold
            font-size: 17px
            line-height: 40px
            color: $font-color
            letter-spacing: 0.03em

            &:focus
            /*border-color: #F2F2F2*/


            &::placeholder
                letter-spacing: 0.03em
                font-weight: bold
                font-size: 20px
                line-height: 40px

        &__animated-border
            position: absolute
            bottom: 0
            height: 2px
            width: 0
            background-color: $font-color

</style>
