<template>
    <div class="input">
        <input
               class="input__component"
               :type="type"
               :placeholder="placeholder"
               :value="value"
               @input="handleChange"
               @focus="toggleDivider(true)"
               @blur="toggleDivider(false)"
        />
        <div ref="border" class="input__animated-border"></div>
    </div>
</template>

<script>

    export default {
        name: 'Input',
        components: {
        },
        props: {
            value: {
                type: String,
            },
            type: {
                type: String,
                default: "text"
            },
            placeholder: {
                type: String,
                default: ""
            },
        },
        methods: {
            handleChange(event) {
                this.$emit('input', event.target.value)
            },
            toggleDivider(show) {
                const options = show ? [0, '100%'] : ['100%', 0];
                this
                    .$anime
                    .timeline()
                    .add({
                        targets: this.$refs.border,
                        width: options,
                        easing: "easeInOutQuad",
                    });
            }
        }
    }
</script>

<style lang="sass">

    .input
        width: 100%
        position: relative

        &__component
            padding: 0 5px
            height: 60px
            width: 100%
            outline: none
            box-shadow: none
            border: none
            background-color: $bgc
            border-bottom: 2px solid $border-color

            box-sizing: border-box
            border-radius: 0
            -webkit-appearance: none
            font-weight: bold
            font-size: 17px
            line-height: 40px
            color: $font-color
            letter-spacing: 0.03em

            &:focus
            /*border-color: #F2F2F2*/


            &::placeholder
                letter-spacing: 0.03em
                font-weight: bold
                font-size: 20px
                line-height: 40px

        &__animated-border
            position: absolute
            bottom: 0
            height: 2px
            width: 0
            background-color: $font-color

</style>
