import Vue from 'vue'
import Vuex from 'vuex'
import application from "./modules/application";
import ui from "./modules/ui";
import contacts from "./modules/contacts";
import modal from "./modules/modal";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    application,
    ui,
    contacts,
    modal
  },
  strict: debug,
})
