<template>
    <div class="home-page">
        <Hero/>
        <div
                id="main-content"
                class="home-page__content"
        >
            <Services/>
            <FeaturedWorks/>
            <GetInTouch :onFormSubmit="onSubmitForm"/>
        </div>
    </div>
</template>

<script>
    import VueScrollTo from 'vue-scrollto'
    import { mapGetters, mapState } from "vuex";
    import * as responsiveConf from '@/config/responsive'

    import Hero from '@/components/home/Hero'
    import Services from '@/components/home/Services'
    import FeaturedWorks from "@/components/home/FeaturedWorks";
    import GetInTouch from "@/components/home/GetInTouch";

    export default {
        name: 'Home',
        components: {
            FeaturedWorks,
            Hero,
            Services,
            GetInTouch
        },
        computed: {
            ...mapState({
                scrollOffset: state => state.ui.scrollOffset,
                device: state => state.ui.device,
            }),
            ...mapGetters([
                'main',
            ]),
        },
        watch: {
            scrollOffset(newValue, oldValue) {
                const offset = this.device === responsiveConf.DEVICE.MOBILE ? 0 : -100;
                if (newValue > oldValue
                    && oldValue === 0
                    && window.pageYOffset < 200) {
                    VueScrollTo.scrollTo('#main-content', 1000, { offset: offset });
                }
            }
        },
        mounted() {
            document.title = this.main.meta_title;
            document.querySelector('meta[name="description"]').setAttribute("content", this.main.meta_description);
        },
        methods: {
            onSubmitForm: function() {
                window.dataLayer.push({
                    'eventCategory': 'ContactForm',
                    'eventAction': 'Click',
                    'eventLabel': 'contact_form_main_page_success',
                    'event': 'contact_form_main_page_success'
                });
            },
        },
    }
</script>

<style lang="sass">
    .home-page

        &__title
            font-size: 40px
            line-height: 50px
            font-weight: bold
            text-align: center
            letter-spacing: 0.03em

        &__content
            display: flex
            flex-wrap: wrap
            margin: 0 0 100px 0


        /*  MOBILE VERSION  */





        &-mob
            .team-page
            &__title
                max-width: 100%

            &__employee
                width: 100%
                margin: 0
                height: 103vw

</style>
