<template>
    <div class="get-in-touch">
        <PageHeader
                class="get-in-touch__header"
                :textSize="TEXT_SIZES.BIG"
                :header="translations.contacts_title"
        />
        <div class="get-in-touch__content">
            <ContactForm
                    :onFormSubmit="onFormSubmit"
                    :title="translations.contacts_title"
            />
        </div>

    </div>
</template>

<script>

    import ContactForm from '@/components/common/ContactForm'
    import PageHeader, { TEXT_SIZES } from '@/components/common/PageHeader'
    import {mapGetters} from "vuex";

    export default {
        name: 'GetInTouch',
        components: {
            ContactForm,
            PageHeader
        },
        props: {
            onFormSubmit:{
                type: Function,
                default: () => {}
            }
        },
        data: function () {
            return {
                TEXT_SIZES
            }
        },
        computed: {
            ...mapGetters([
                'translations'
            ])
        }
    }
</script>

<style lang="sass">
    .get-in-touch
        @include grid(12, 2)
        position: relative
        width: 100%

        &__header
            grid-column-start: 5
            grid-column-end: 9
            text-align: center
            @include mq(tablet)
                grid-column-start: 4
                grid-column-end: 10
            @include mq(phone)
                grid-column-start: 1
                grid-column-end: 13

        &__content
            width: 100%
            grid-column-start: 5
            grid-column-end: 9
            @include mq(tablet)
                grid-column-start: 4
                grid-column-end: 10
            @include mq(phone)
                grid-column-start: 1
                grid-column-end: 13


</style>
