<template>
    <div class="header">
        <h2 class="header__text" v-bind:style="textSize" v-html="header">
        </h2>
    </div>
</template>

<script>

    export const TEXT_SIZES = {
        BIG: {
            fontSize: '42px',
            fontWeight: 'bold',
            lineHeight: '50px',
        },
        MEDIUM : {
            fontSize: '28px',
            fontWeight: '500',
            lineHeight: '36px'
        },
        LOW : {
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '24px'
        }
    };

    export default {
        name: 'PageHeader',
        props: {
            textSize: {
                type: Object,
                default: () => (TEXT_SIZES.BIG)
            },
            header: {
                type: String,
                default: () => ('')
            }
        },
        mounted() {
            this
                .$anime
                .timeline()
                .add({
                    targets: '.header__text',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    delay: 700
                })
        }
    }
</script>

<style lang="sass">
    .header
        width: 100%
        margin: 60px 0 80px 0
        box-sizing: border-box
        a
            color: $font-color
        @include mq(phone)
            padding-top: 10px
            margin-bottom: 30px

        &__text
            font-weight: 500
            font-size: 21px

</style>
