<template>
    <transition name="fade" appear>
        <div class="button">
            <button
                    @click="onClick"
                    type="button"
                    class="button__element"
                    :disabled="disabled"
            >
                <slot/>
            </button>
            <div ref="border" class="button__animated-border"></div>
        </div>
    </transition>

</template>

<script>
    export default {
        name: 'Button',
        components: {},
        props: {
            onClick: {
                type: Function,
                required: true
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
    }
</script>

<style lang="sass">

    .fade-enter-active, .fade-leave-active
        transition: all .5s

    .fade-enter, .fade-leave-to
        transform: translateX(200px)
        opacity: 0

    .button
        position: relative

        &:hover
            .button__animated-border
                width: 100%

        &__element
            cursor: pointer
            width: 100%
            @include transition(color)
            position: relative
            min-height: 50px
            padding: 0 30px
            background-color: transparent
            color: $font-color
            border: none
            outline: none
            border-bottom: 2px solid $border-color
            font-size: 21px
            font-weight: bold
            line-height: 22px
            letter-spacing: 0.01em

            &:disabled
                color: $darken-font-color

            &:focus
                border-color: $font-color

        &__animated-border
            @include transition(width)
            position: absolute
            bottom: 0
            height: 2px
            width: 0
            background-color: $font-color
</style>
