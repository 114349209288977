<template>
    <div class="hero">
        <div class="hero__content">
            <AnimatedText
                    key="title"
                    :text="main.title"
                    class="hero__title"
            />
            <AnimatedText
                    key="sub-title"
                    :text="main.subtitle"
                    class="hero__sub-title"
            />
            <div class="hero__button">
                <Button :onClick="goAboutPage">
                    {{translations.link_titles.about}}
                </Button>
            </div>
        </div>
    </div>

</template>

<script>
    import Button from '@/components/common/button'
    import AnimatedText from "@/components/common/AnimatedText";
    import {mapGetters} from "vuex";

    export default {
        name: 'Hero',
        components: {
            AnimatedText,
            Button
        },
        props: {
            text: {
                type: Object,
                default: () => ({
                    title: '',
                    subtitle: ''
                }),
            },
        },
        computed: {
            ...mapGetters([
                'translations',
                'main'
            ]),
        },
        mounted() {
            this
                .$anime
                .timeline()
                .add({
                    targets: '.hero__button',
                    opacity: [0, 1],
                    easing: 'linear',
                    duration: 750,
                    delay : 2000,
                })
        },
        methods: {
            goAboutPage() {
                this.$router.push({name: 'about'})
            }
        },
    }
</script>

<style lang="sass">

    .hero
        min-height: 450px
        height: calc(100vh -  #{$header-height})
        display: grid
        grid-template-columns: repeat(12, minmax(10px, 1fr))
        @include mq(phone)
            display: block
            height: calc(100vh - 120px)

        &__content
            grid-column-start: 3
            grid-column-end: 10
            align-self: center
            @include mq(phone)
                height: 90%
                padding-top: 10px
                display: flex
                flex-direction: column
                justify-content: space-around

        &__title
            font-size: 42px
            line-height: 50px
            font-weight: bold
            letter-spacing: 0.03em
            @include mq(small)
                font-size: 30px

            @include mqh(phone)
                font-size: 35px
            @include mqh(small-phone)
                font-size: 25px

        &__sub-title
            margin: 80px 0
            font-weight: 500
            font-size: 21px
            line-height: 36px
            letter-spacing: 0.01em
            @include mq(phone)
                margin: 0

            @include mqh(phone)
                font-size: 16px
            @include mqh(small-phone)
                font-size: 14px

        &__button
            margin-top: 130px
            width: 100%
            display: flex
            justify-content: flex-end
            @include mq(phone)
                padding-bottom: 40px
                margin: 0

</style>
