<template>
    <div class="featured-works" v-if="this.main">

        <PageHeader
                class="services__header featured-works__header"
                :textSize="TEXT_SIZES.BIG"
                :header="this.main.featured_projects"
        />

        <div class="featured-works__content">
            <CardList
                    :items="featureProjects"
            />
        </div>

        <h2 class="featured-works__content-button">
            <Button :onClick="goProjectsPage">{{translations.projects_all}}</Button>
        </h2>

        <Banner
                class="featured-works__banner"
                :linkTo="{name: 'project-detail', params: {id: 'dominos'}}"
                :text="this.main.dominos_link_text"
                :btnText="this.main.dominos_link_button"
        />

        <ReviewCarousel
                class="featured-works__carousel"
        >

        </ReviewCarousel>
    </div>

</template>

<script>

    import {mapGetters} from "vuex";

    import CardList from "@/components/common/lists/card/CardList";
    import Banner from "@/components/common/Banner";
    import ReviewCarousel from "@/components/common/ReviewCarousel";
    import PageHeader, {TEXT_SIZES} from '@/components/common/PageHeader'
    import Button from "../common/button";

    export default {
        name: 'FeaturedWorks',
        components: {
            Button,
            CardList,
            PageHeader,
            Banner,
            ReviewCarousel
        },
        data() {
            return {
                TEXT_SIZES
            }
        },
        computed: {
            ...mapGetters([
                'main',
                'translations',
                'featureProjects'
            ]),
        },
        methods: {
            goProjectsPage() {
                this.$router.push({name: 'projects'})
            }
        },
    }
</script>

<style lang="sass">
    .featured-works
        position: relative
        width: 100%
        @include grid(12, 2)
        margin-bottom: 100px
        @include mq(phone)
            margin-bottom: 20px

        &__header
            grid-column-start: 3
            grid-column-end: 11
            z-index: 1
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13


        &__content
            width: 100%
            grid-column-start: 1
            grid-column-end: 13

        &__content-button
            padding-top: 20px
            grid-column-start: 6
            grid-column-end: 8
            @include mq(tablet)
                grid-column-start: 5
                grid-column-end: 9
            @include mq(phone)
                grid-column-start: 3
                grid-column-end: 11

        &__banner
            grid-column-start: 3
            grid-column-end: 11
            margin: 60px 0
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

        &__carousel
            min-height: 200px
            grid-column-start: 3
            grid-column-end: 11
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

</style>
