<template>
    <div :class="{'footer-component': true}">
        <div class="footer-component__content">
            <div class="footer-component__logo" @click="goHome">
                <Logo size="medium"/>
            </div>

            <div class="footer-component__social">
                <div class="footer-component__link">
                    <a :href="config.facebook_link" target="_blank">Facebook</a>
                </div>
                <div class="footer-component__link">
                    <a :href="config.linkedin_link" target="_blank">Linkedin</a>
                </div>
            </div>

            <div class="footer-component__contacts">

                <div class="footer-component__contacts_talk">
                    <div class="footer-component__contacts-text">
                        <span>{{config.contact_text}}</span>
                    </div>
                    <div class="footer-component__link">
                        <a :href="`mailto:${config.email}`" target="_blank">{{config.email}}</a>
                    </div>
                    <div class="footer-component__link">
                        <a :href="`tel:${config.phone}`" target="_blank">{{config.phone}}</a>
                    </div>
                </div>

                <div class="footer-component__contacts_join">
                    <div class="footer-component__contacts-text">
                        <span>{{config.career_text}}</span>
                    </div>
                    <div class="footer-component__link">
                        <a :href="`mailto:${config.career_email}`" target="_blank">{{config.career_email}}</a>
                    </div>
                </div>

                <div class="footer-component__contacts_ceo">
                    <div class="footer-component__contacts-text">
                        <span>{{config.ceo_title}}</span>

                    </div>
                    <div class="footer-component__link footer-component__link_ceo">
                        <span>{{config.ceo_name}}</span>
                        <a :href="`mailto:${config.ceo_email}`" target="_blank">{{config.ceo_email}}</a>
                    </div>
                </div>

                <div class="footer-component__contacts-text">
                    <span>{{config.address}}</span>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
    import Logo from '@/components/common/Logo.vue'
    import { mapGetters } from "vuex";

    export default {
        name: 'Footer',
        components: {
            Logo,
        },
        props: {
            mobView: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters([
                'config'
            ]),
        },
        methods: {
            changeLanguage(lang) {
                this.setCookie(lang);
                this.$emit('changeLang')
            },
            setCookie(value) {
                const d = new Date;
                d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 365);
                document.cookie = `language=${value};path=/;expires=${d.toGMTString()}`;
            },
            goHome() {
                this.$router.push({ name: 'home' })
            }
        }
    }
</script>

<style scoped lang="sass">
    .footer-component
        @include fxc
        box-sizing: border-box
        height: fit-content
        width: 100%
        font-size: 17px
        padding: 0 80px
        @include mq(phone)
            padding: 0

        &__content
            width: 100%
            border-top: $border
            max-width: $max-content-width
            @include grid(12)
            padding: 55px 0 20px 0
            @include mq(phone)
                padding: 80px 20px 50px 20px
                display: flex
                flex-direction: column

        &__logo
            grid-column-start: 1
            grid-column-end: 3
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 4

        &__social
            display: flex
            grid-column-start: 3
            grid-column-end: 5
            @include mq(tablet)
                flex-direction: column
                grid-column-start: 4
                grid-column-end: 5
            @include mq(phone)
                flex-direction: column
                padding: 60px 0

        &__link
            margin-right: 5%
            padding: 15px 0
            a
                color: $font-color
                font-weight: bold
                letter-spacing: 0.03em
                text-decoration: none
            &_ceo
                display: flex
                flex-direction: column

        &__contacts
            text-align: start
            grid-column-start: 7
            grid-column-end: 13
            @include grid(3,1)
            @include mq(tablet)
                @include grid(2,1)
                grid-column-start: 6

            &_talk
                grid-column-start: 1
                grid-column-end: 2
                @include mq(tablet)
                    grid-column-start: 1
                    grid-column-end: 2
                @include mq(phone)
                    padding-bottom: 30px
                    grid-column-start: 1
                    grid-column-end: 4

            &_join
                grid-column-start: 2
                grid-column-end: 3
                @include mq(tablet)
                    grid-column-start: 2
                    grid-column-end: 3
                @include mq(phone)
                    padding-bottom: 30px
                    grid-column-start: 1
                    grid-column-end: 4

            &_ceo
                grid-column-start: 3
                grid-column-end: 4
                @include mq(tablet)
                    grid-column-start: 2
                    grid-column-end: 3
                @include mq(phone)
                    padding-bottom: 30px
                    grid-column-start: 1
                    grid-column-end: 4

            &-text
                grid-column-start: 1
                grid-column-end: 3
                @include mq(tablet)
                    grid-column-start: 2
                    grid-column-end: 3
                @include mq(phone)
                    grid-column-start: 1
                    grid-column-end: 3

                white-space: pre-wrap
                padding: 15px 0
                color: $font-color
                letter-spacing: 0.03em
                font-weight: 100


</style>
