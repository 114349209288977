import Vue from 'vue'
import Router from 'vue-router'
import Contact from './views/Contact'
import Projects from './views/Projects'
import Project from './views/Project'
import Team from './views/Team'
import Home from "./views/Home";
import About from "./views/About";
import ProjectDetail from "./views/ProjectDetail";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
    },
    {
      path: '/projects/:id',
      name: 'project',
      component: Project,
    },
    {
      path: '/team',
      name: 'team',
      component: Team,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/projects-details/:id',
      name: 'project-detail',
      component: ProjectDetail,
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
