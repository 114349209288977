import * as http from '@/utils/http';
import url from "@/config/url";

export default class Application {

    static getSettings = () => http.get(`${url.api}settings/`);

    static getProjects = () => http.get(`${url.api}project/`);

    static getProjectCategory = () => http.get(`${url.api}project_category/`);

    static getReviews = () => http.get(`${url.api}reviews/`);

    static postContact = (contactData) => http.post(`${url.api}contact/`, contactData);

}