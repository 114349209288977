<template>
    <div class="check"
    >
        <input
                class="check__input"
                type="checkbox"
                :checked="checked"
        />
        <span
                @click="handleChange"
                class="check__box"
        />
        <label class="check__label" v-html="label"></label>
    </div>
</template>

<script>

    export default {
        name: 'CheckBox',

        props: {
            checked: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ''
            },
        },
        methods: {
            handleChange() {
                this.$emit('change', !this.checked)
            },
        }
    }
</script>

<style lang="sass">

    .check
        padding-left: 30px
        display: flex
        align-items: center
        user-select: none
        height: 40px
        font-size: 21px
        line-height: 25px
        color: $font-color

        &:hover
            .check__input:checked + .check__box
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2ZM10 17L5 12.1923L6.4 10.8462L10 14.3077L17.6 7L19 8.34615L10 17Z' fill='%23F2F2F2'/%3E%3C/svg%3E%0A")
            .check__input + .check__box
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.7777 4.22223V19.7778H4.22219V4.22223H19.7777ZM19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2Z' fill='%234F4F4F'/%3E%3C/svg%3E%0A")

        &__input
            position: absolute
            height: 1px
            width: 1px
            overflow: hidden
            clip: rect(0 0 0 0)

        &__box
            margin-left: -30px
            position: absolute
            height: 25px
            width: 25px

        &__label
            font-size: 15px



        &__input:checked + &__box
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' fill-rule='evenodd' clip-rule='evenodd' d='M19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2ZM10 17L5 12.1923L6.4 10.8462L10 14.3077L17.6 7L19 8.34615L10 17Z' fill='%23F2F2F2'/%3E%3C/svg%3E%0A")
            background-repeat: no-repeat

        &__input + &__box
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.8' fill-rule='evenodd' clip-rule='evenodd' d='M19.7777 4.22223V19.7778H4.22219V4.22223H19.7777ZM19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2Z' fill='%234F4F4F'/%3E%3C/svg%3E%0A")
            background-repeat: no-repeat

        &__input:focus + &__box
            box-shadow: 0 0 0 3px #ededed
            background-color: #ededed
            border-radius: 25%

        &__input:checked:focus + &__box
            box-shadow: 0 0 0 3px #ededed
            background-color: #ededed
            border-radius: 25%

        &__input:disabled + &__box
            border: 1px solid #6d6d6d

        &__input:checked:disabled + &__box
            background-color: #6d6d6d


</style>
