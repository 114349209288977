<template>
    <div class="projects">

        <div class="projects__header">
            <PageHeader
                    :header="translations.link_titles.projects"
                    :subheader="translations.projects_all"
            />
        </div>

        <div class="projects__list">
            <CardList
                    :items="projects"
            />
        </div>
    </div>
</template>

<script>

    import CardList from "@/components/common/lists/card/CardList";
    import PageHeader from "@/components/common/PageHeader";
    import {mapGetters} from "vuex";

    export default {
        name: 'Projects',
        components: {
            CardList,
            PageHeader
        },
        computed: {
            ...mapGetters([
                'translations',
                'projects',
                'projectsPage'
            ]),
        },
        data() {
            return {
                activeFilter: 'all',
            }
        },
        mounted() {
            document.title = this.projectsPage.meta_title;
            document.querySelector('meta[name="description"]').setAttribute("content", this.projectsPage.meta_description);
        },
        updated() {
            const video = document.querySelector('video');
            const image = document.querySelectorAll('img');
            if (video) {
                video.onloadeddata = () => {
                    this.$emit('uploaded');
                }
            } else if (image.length !== 0) {
                image[image.length - 1].onload = () => {
                    this.$emit('uploaded');
                }
            }
        },
        methods: {
            changeFilter(filter) {
                this.activeFilter = filter;
            },
            actualImg(project) {
                return project.width_on_main === 'half' ? project.main_page_image_rectangular[800] : project.main_page_image_square[800];
            },
        },
    }
</script>

<style scoped lang="sass">
    .projects
        padding-bottom: 50px
        display: grid
        grid-template-columns: repeat(12, minmax(10px, 1fr))

        &__header
            grid-column-start: 1
            grid-column-end: 13

        &__list
            grid-column-start: 1
            grid-column-end: 13


</style>
