
// initial state
const state = {
    data: null
};

// getters
const getters = {
    showModal: state => !!state.data,
    modalData: state => state.data
};

// mutations
const mutations = {
    setModalData(state, payload) {
        state.data = payload;
    },
    clearModalData(state) {
        state.data = null;

    }
};

// actions async tasks
const actions = {

};

export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
