/**
 * @module config/url
 */
/**
 * Default config
 * @type {{api: string}}
 */
const commons = {
    api: process.env.VUE_APP_API_BASE_URL
};
export default process.env.NODE_ENV === 'production'
    ? {
        ...commons,
    }
    : {
        ...commons,
        api: process.env.VUE_APP_API_DEV_URL
    };
