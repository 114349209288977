<template>
    <div
            v-if="reviews"
            :style="{height: `${maxHeight}px`}"

    >
        <carousel
                :class="{'carousel-container': true, 'carousel-container_swipe-disable': isMobile}"
                ref="carousel"
                :controls="false"
                :indicators="true"
                :autoplay="false"
                :interval="15000"
                :data="renderData"
        >

        </carousel>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import Review from "./Review";

    export default {
        name: 'ReviewCarousel',
        components: {},

        data() {
            return {
                maxHeight: 0
            }
        },
        computed: {
            ...mapGetters([
                'reviews',
                'isMobile'
            ]),
            renderData: function() {
                return this.reviews.map(review => ({
                    content(createElement) {
                        return createElement(Review, {
                            props: {
                                review: review
                            },
                            class: 'carousel-container__element'
                        });
                    },
                }))
            }
        },
        methods: {},
        mounted() {
            this.$watch(
                () => this.$refs.carousel.index,
                () => {
                    this.maxHeight = this.$refs.carousel.$el.clientHeight > this.maxHeight ? this.$refs.carousel.$el.clientHeight : this.maxHeight;
                }
            );
            this.maxHeight = this.$refs.carousel.$el.clientHeight;
        },
    };
</script>

<style lang="sass">
    .carousel-container
        &_swipe-disable
            touch-action: pan-y !important

        &__element
            min-height: 380px
            @include mq(tablet)
                min-height: 440px
            @include mq(phone)
                min-height: 640px

</style>