<template>
    <div class="animated-text">
        <div class="animated-text__word"
             v-for="(word, index) in text.split(' ')"
             v-bind:key="index"
        >
                     <span class="animated-text__letter"
                           v-for="(letter, index) in word"
                           v-bind:key="index"
                     >
                    {{letter}}
                </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AnimatedText',
        components: {},
        props: {
            text: {
                type: String,
                default: () => ''
            },
        },
        mounted() {
            this
                .$anime
                .timeline()
                .add({
                    targets: '.animated-text .animated-text__letter',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    delay: (el, i) => 10 * (i + 1)
                })
        }
    }
</script>

<style lang="sass">
    .animated-text
        display: flex
        flex-wrap: wrap
        &__word
            display: flex
            // 3.5 Common coefficient for font size (FS) title (42px) and subtitle (21px) relative space width (SW) pixel (FS: 21px - SW: 6px)
            &:not(:last-child)
                padding-right: calc(1em / 3.5)
            &__letter
                display: flex
</style>
