<template>
    <div class="loader-component">
        <Lottie
                className="animation"
                renderer="svg"
                :loop="true"
                :autoplay="true"
                :animationData=lottieData
                @onComplete=onComplete
                @onLoopComplete=onComplete
        />
        </div>
</template>

<script>
    import {mapMutations} from "vuex";
    import Lottie from 'vue-lottie-web';
    import lottieData from './logo.json';

    export default {
        name: 'Loader',
        components: {
            Lottie
        },
        data() {
            return {
                lottieData,
            };
        },
        methods: {
            ...mapMutations([
                'firstLoadComplete'
            ]),
            onComplete() {
                this.firstLoadComplete();
            },
        }
    }
</script>

<style scoped lang="sass">
    .loader-component
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100vh
        position: fixed
        top: 0
        left: 0
        background: $bgc
        z-index: 6
    .lottie
        font-size: unset!important
        width: 100vw
</style>
