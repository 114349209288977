<template>
    <div class="card-list">
        <Card
                v-for="item in items"
                v-bind:key="item.id"
                :item="item"
                class="card-list__item"
        >
        </Card>
    </div>
</template>

<script>
    import Card from "./Card";

    export default {
        name: 'CardList',
        components: {
            Card
        },
        props: {
            items: {
                type: Array,
                default: () => ([])
            }
        },
        beforeCreate() {
            window.addEventListener("load", () => {
                this.$emit('uploaded')
            });
        },
        mounted() {
        },
        updated() {
            this.$emit('uploaded');
        },
    }
</script>

<style lang="sass">

    $gap-h: 15px
    $gap-v: 10px

    .card-list
        display: flex
        margin: 0 -10px
        flex-wrap: wrap

        &__item
            width: calc(100% / 3  - #{$gap-h} * 2)
            margin: $gap-v $gap-h
            @media (max-width: 1000px)
                width: calc(100% / 2  - #{$gap-h} * 2)
            @media (max-width: 760px)
                width: calc(100% / 1  - #{$gap-h} * 2)


</style>
