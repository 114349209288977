<template>
    <div class="project-details">
        <div class="project-details__content">

            <PageHeader
                    class="project-details__title"
                    :header="project.title"
                    :textSize="textSize"
                    v-scroll-to="{ element: '#solutions', offset: this.device === responsiveConf.DEVICE.MOBILE  ?  -60 : -230 }"
            />

            <div class="project-details__about-block">
                <div class="project-details__block-article"
                     v-for="block in blocks.about.items"
                     :key="block.title"
                >
                    <h3 class="project-details__block-article-header">{{block.title}}</h3>
                    <div class="project-details__block-about-text"
                         v-html="block.content">
                    </div>
                </div>
            </div>
            <div class="project-details__goals-and-solutions" v-if="blocks['goals-solutions']">
                <template
                        v-for="(block, index) in blocks['goals-solutions'].items"
                >
                    <div v-bind:key="index" class="project-details__block-article">

                        <h1 class="project-details__block-article-header">
                            {{block.title}}
                        </h1>
                        <p class="project-details__block-article-text" v-html="block.content"></p>
                    </div>
                </template>
            </div>

            <h1 id="solutions" class="project-details__header" v-html="blocks.solutions.title">
            </h1>

            <div class="project-details__solutions">
                <div class="project-details__solutions-column">
                    <Accordion
                            v-for="(block, index) in blocks.solutions.items.slice(0, blocks.solutions.items.length / 2 + 1)"
                            v-bind:key="index"
                            :title="block.title"
                    >
                        <p v-html="block.content"></p>
                    </Accordion>
                </div>
                <div class="project-details__solutions-column">
                    <Accordion
                            v-for="(block, index) in blocks.solutions.items.slice(blocks.solutions.items.length / 2 + 1)"
                            v-bind:key="index"
                            :title="block.title"
                    >
                        <p v-html="block.content"></p>
                    </Accordion>
                </div>
            </div>

            <!--            выровнять-->
            <!--            <Button>-->
            <!--                {{settings.translations.contact_form.title}}-->
            <!--            </Button>-->


            <div class="project-details__challenges-and-result">

                <div class="project-details__block-article"
                     v-for="(list, index) in listBlocks"
                     v-bind:key="index"
                >
                    <h1 class="project-details__block-article-header">
                        {{list.title}}
                    </h1>
                    <div class="project-details__list-block"
                         v-for="(content, index) in list.contents"
                         v-bind:key="index"
                         v-html="content"
                    >
                    </div>
                </div>

            </div>

            <GetInTouch class="project-details__contacts"
                        :onSubmit="onSubmitForm"
            />

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import Accordion from "@/components/common/Accordion";
    import GetInTouch from "@/components/home/GetInTouch";
    import PageHeader, { TEXT_SIZES } from "@/components/common/PageHeader";
    import * as responsiveConf from '@/config/responsive';

    export default {
        name: 'ProjectDetail',
        components: {
            Accordion,
            GetInTouch,
            PageHeader
            // Button

        },
        computed: {
            ...mapState({
                device: state => state.ui.device,
            }),
            ...mapGetters([
                'translations',
                'dominosCases',
                'device'
            ]),
            textSize: function() {
                return this.device === responsiveConf.DEVICE.MOBILE ? TEXT_SIZES.MEDIUM : TEXT_SIZES.BIG
            },
            project() {
                // TODO push it by props
                return this.dominosCases
            },
            listBlocks() {
                return this.project.list_blocks.reduce((array, group) => (
                    [
                        ...array,
                        {
                            title: group.title,
                            contents: group.list_block_elements.map(element => element.content)
                        }
                    ]
                ), []);
            },

            blocks() {
                return this.project.simple_content_block_groups.reduce((map, group) => (
                    {
                        ...map,
                        [group.code]: {
                            items: group.simple_content_blocks,
                            title: group.title
                        }
                    }
                ), {});
            }
        },
        data() {
            return {
                responsiveConf
            }
        },
        mounted() {
            document.title = this.project.meta_title;
            document.querySelector('meta[name="description"]').setAttribute("content", this.project.meta_description);
        },
        beforeCreate() {
            window.addEventListener("load", () => {
                this.$emit('uploaded')
            });
        },
        updated() {
            this.$emit('uploaded');
        },
        methods: {
            onSubmitForm: function(data) {
                this.$emit('sendMessage', data);
            }
        },
    }

    // HEADER, subheader
</script>

<style lang="sass">
    .project-details
        position: relative
        margin-bottom: 100px
        @include grid(12, 1)
        @include mq(phone)
            margin-top: 0
            padding-top: 0

        &__content
            grid-column-start: 1
            grid-column-end: 13
            @include grid(12, 1)


        &__title
            grid-column-start: 3
            grid-column-end: 11
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13
            z-index: 1

        /*REUSE!!!                */

        &__about-block
            grid-column-start: 3
            grid-column-end: 11
            @include grid(2, 1, 20)
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13
                grid-template-columns: 1fr

        &__block-article
            margin-bottom: 30px
            z-index: 1
            @media (max-width: 760px)
                margin-bottom: 20px

        &__block-article-header
            display: flex
            align-items: center
            font-weight: 500
            font-size: 21px
            line-height: 22px
            margin-bottom: 30px


        &__block-article-text
            font-weight: 500
            font-size: 21px
            line-height: 36px


        &__block-about-text
            margin-top: 35px
            font-weight: 300
            font-size: 17px
            line-height: 28px
            letter-spacing: 0.01em

        &__goals-and-solutions, &__solutions, &__challenges-and-result
            grid-column-start: 3
            grid-column-end: 11
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-gap: 0 20px
            margin: 40px 0
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13
            @include mq(phone)
                grid-template-columns: repeat(1, 1fr)

        &__solutions-column
            display: flex
            flex-direction: column

        &__header
            grid-column-start: 3
            grid-column-end: 11
            margin: 40px 0
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

        &__contacts
            grid-column-start: 1
            grid-column-end: 13
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

        &__list-block
            background-color: $panel-color
            padding: 10px 20px
            border-top: $border
            border-bottom: $border
            margin: -1px
            font-weight: bold
            font-size: 17px
            line-height: 28px
            letter-spacing: 0.01em

</style>

