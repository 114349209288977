import * as responsiveConf from '@/config/responsive'

// initial state
const state = {
    scrollOffset: 0,
    windowWidth: 0,
    device: responsiveConf.DEVICE.DESKTOP
};

// getters
const getters = {
    responsiveImage: state => imageSrc => {
        const closestResolution = Object.keys(imageSrc).find(imageResolution => imageResolution > state.windowWidth) || '1600';
        return imageSrc[closestResolution];
    },
    device: state =>  state.device,
    isMobile: state =>  state.device === responsiveConf.DEVICE.MOBILE,
};

// mutations
const mutations = {
    updateWindowWidth(state, payload) {
        state.windowWidth = payload;
        state.device = responsiveConf.getDeviceByWidth(payload);
    },
    updateScrollOffset(state, payload) {
        state.scrollOffset = payload;
    }
};

// actions async tasks
const actions = {};

export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}
