<template>
  <div :class="{'project': true, 'project-mob': mobile}"
       v-if="actualProject && projectIndex !== -1">

    <div class="project__content">
      <PageHeader
          class="project__title-block"
          :header="actualProject.short_description"
          :subheader="actualProject.name"
          :textSize="TEXT_SIZES.BIG"
      />

      <!-- FIRST BLOCK -->
      <div v-if="actualProject.first_block_title" class="project__text-content">
        <div class="project__text-content-body">
          <div
              v-if="actualProject.first_block_text_first"
              class="project__text-content-body-item"
          >
            <div class="project__text-content-title">{{ actualProject.first_block_title }}</div>
            <div v-html="actualProject.first_block_text_first"/>
          </div>

          <div
              v-if="actualProject.first_block_text_second"
              class="project__text-content-body-item"
          >
            <div class="project__text-content-title">
              {{ actualProject.first_block_second_title }}
            </div>
            <div v-html="actualProject.first_block_text_second"/>
          </div>
        </div>
      </div>

      <!-- MAIN MEDIA BLOCK -->
      <div class="project__main-page-image" v-if="actualProject.project_page_image.full || actualProject.video">
        <img v-if="!actualProject.show_video && actualProject.project_page_image || mobile"
             :src="this.responsiveImage(actualProject.project_page_image)" alt="">
        <video :src="actualProject.video"
               id="video-container-banner"
               width="100%"
               v-if="actualProject.show_video && !mobile && actualProject.video"
               autoplay
               muted
               playsinline
               webkit-playsinline
               loop></video>
      </div>

      <div class="project__content-blocks">
        <div v-for="block of actualProject.content"
             v-html="getBlock(block)"
             class="project__content-blocks-item"
             :class="block.width === 'full' ? 'project__content-blocks-item_full': 'project__content-blocks-item_half'"

             :key="block.id"
        >
        </div>
      </div>

      <!-- RESULT BLOCK -->
      <div v-show="actualProject.result_title" class="project__text-content">
        <div class="project__text-content-title">
          {{ actualProject.result_title }}
        </div>
        <div class="project__text-content-subtitle"
             v-html="actualProject.result_description"
        >
        </div>
        <div class="project__text-content-body">
          <div class="project__text-content-body-item" v-html="actualProject.result_first_block"></div>
          <div class="project__text-content-body-item" v-html="actualProject.result_second_block"></div>
        </div>
      </div>

      <!--  DOMINOS BANNER -->
      <Banner
          v-if="actualProject.show_dominos_banner"
          class="project__banner"
          :linkTo="{name: 'project-detail', params: {id: 'dominos'}}"
          :text="this.main.dominos_link_text"
          :btnText="this.main.dominos_link_button"
      />

      <!-- technologies BLOCK -->
      <div v-if="actualProject.technologies.length > 0" class="project__text-content">
        <div class="project__text-content-title">
          {{ translations.technologies }}
        </div>
        <div class="project__technologies-list">
          {{ projectTechnologies }}
        </div>
      </div>

      <!-- PARTICIPANTS BLOCK -->
      <div v-if="actualProject.participants.length > 0" class="project__text-content">
        <div class="project__text-content-title">
          {{ translations.participants_title }}
        </div>
        <div>
          <div class="project-page__content-result-team-item"
               v-for="employee of actualProject.participants"
               :key="employee.id">
            <span>{{ employee.first_name }} </span><span>{{ employee.last_name }}</span> -
            <span>{{ employee.job }}</span>
          </div>
        </div>
      </div>

      <!-- REVIEW BLOCK -->
      <Review
          v-if="actualProject.review"
          class="project__review"
          :review="actualProject.review"
      />

      <GetInTouch
          :onFormSubmit="onSubmitForm"
          class="project__contact"
      />

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import PageHeader, {TEXT_SIZES} from "@/components/common/PageHeader";
import GetInTouch from "@/components/home/GetInTouch";
import Banner from "@/components/common/Banner";
import Review from "@/components/common/Review";

export default {
  name: 'Project',
  components: {
    PageHeader,
    GetInTouch,
    Banner,
    Review
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'responsiveImage',
      'projects',
      'main',
      'translations'
    ]),
    actualProject() {
      let projectRight = {};
      for (let project in this.projects) {
        if (this.projects[project].slug === this.$route.params.id) {
          projectRight = this.projects[project];
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.projectIndex = project;
        }
      }
      return projectRight;
    },
    projectTechnologies() {
      return this.actualProject.technologies.map(technology => technology.name).join(' · ')
    },
    nextProject() {
      return this.projects.length === parseInt(this.projectIndex) + 1 ? this.projects[0] : this.projects[parseInt(this.projectIndex) + 1];
    },
  },
  data() {
    return {
      projectIndex: -1,
      TEXT_SIZES
    }
  },
  mounted() {
    document.title = this.actualProject.meta_title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.actualProject.meta_description);

    console.log(this.actualProject.first_block_title);
  },
  updated() {
    if (this.actualProject.meta_title && this.actualProject.meta_description) {
      document.title = this.actualProject.meta_title;
      document.querySelector('meta[name="description"]').setAttribute("content", this.actualProject.meta_description);
    }


    const video = document.querySelector('video');
    const image = document.querySelectorAll('img');
    if (video) {
      video.onloadeddata = () => {
        this.uploaded();
      }
    } else if (image.length !== 0) {
      image[image.length - 1].onload = () => {
        this.uploaded();
      }
    }
  },
  methods: {
    uploaded: function () {
      // Turn Off loader
    },
    onSubmitForm: function () {
      window.dataLayer.push({
        'eventCategory': 'ContactForm',
        'eventAction': 'Click',
        'eventLabel': 'contact_form_projects_page_success',
        'event': 'contact_form_projects_page_success'
      });
    },
    getBlock({type, width, ...rest}) {
      switch (width) {
        case 'full':
          if (type === 'text') {
            return `<div class="project__text-content project__text-content_full">
                                        <div class="project__text-content-title"
                                        >${rest.text_title}</div>
                                         <div class="project__text-content-subtitle"
                                        >${rest.text_subtitle}</div>
                                        <div class="project__text-content-body">
                                            <div class="project__text-content-body-item">${rest.text_block_1}</div>
                                            <div class="project__text-content-body-item">${rest.text_block_2}</div>
                                        </div>
                                    </div>`;
          } else if (type === 'video') {
            return `<div class="project__media-content">
                                        <video src="${rest.video}"
                                             width="100%"
                                             autoplay
                                             muted
                                             playsinline
                                             webkit-playsinline
                                             loop>
                                        </video>
                                    </div>`;
          } else if (type === 'photo_text') {
            //TODO
            console.error('NOT IMPLEMENTED')
            return `<div class="project__media-content">
                                    <img src="${this.responsiveImage(rest.image)}"/>
                                </div>`;

          } else if (type === 'video_text') {
            //TODO
            console.error('NOT IMPLEMENTED')
            return `<div class="project__media-content">
                                        <video src="${rest.video}"
                                             width="100%"
                                             autoplay
                                             muted
                                             playsinline
                                             webkit-playsinline
                                             loop>
                                        </video>
                                    </div>`;
          } else {
            return `<div class="project__media-content">
                                    <img src="${this.responsiveImage(rest.image)}"/>
                                </div>`;
          }

        case 'half':
          if (type === 'text') {
            return `<div class="project__text-content">
                                        <div
                                            class="project__text-content-title"
                                        >
                                                ${rest.text_title}
                                        </div>
                                        <div class="project__text-content-body">
                                            <div class="project__text-content-body-item project__text-content-body-item_${rest.text_position}">
                                                  ${rest.text_block_1}
                                            </div>
                                        </div>
                                    </div>`;
          } else if (type === 'video') {
            return `<div class="project__media-content">
                                        <video src=${rest.video}
                                             width="100%"
                                             autoplay
                                             muted
                                             playsinline
                                             webkit-playsinline
                                             loop>
                                        </video>
                                    </div>`;
          } else if (type === 'photo_text') {
            //TODO
            console.error('NOT IMPLEMENTED')
            return `<div class="project__media-content">
                                    <img src="${this.responsiveImage(rest.image)}"/>
                                </div>`;
          } else if (type === 'video_text') {
            //TODO
            console.error('NOT IMPLEMENTED')
            return `<div class="project__media-content">
                                        <video src=${rest.video}
                                             width="100%"
                                             autoplay
                                             muted
                                             playsinline
                                             webkit-playsinline
                                             loop>
                                        </video>
                                    </div>`;
          } else {
            return `<div class="project__media-content">
                                    <img src="${this.responsiveImage(rest.image)}"/>
                                </div>`;
          }
      }
    }
  },
}
</script>

<style lang="sass">
.project
  ul
    white-space: normal
    list-style-position: inside
    @include mq(phone)
      padding-left: 15px


  &__content
    @include grid(12, 8, 20)

  &__title-block
    grid-column-start: 3
    grid-column-end: 11
    @include mq(tablet)
      grid-column-start: 1
      grid-column-end: 13

  &__contact
    margin-top: 50px
    grid-column-start: 1
    grid-column-end: 13

  &__main-page-image
    width: 100%
    grid-column-start: 1
    grid-column-end: 13
    margin-bottom: -10px

    img, video
      width: 100%
      height: 100%
      object-fit: cover

  &__content-blocks
    grid-column-start: 1
    grid-column-end: 13
    @include fxc()
    justify-content: flex-start
    flex-wrap: wrap
    margin: 0 -10px

  &__content-blocks-item
    &_full
      width: 100%

    &_half
      width: 50%
      @include mq(phone)
        width: 100%

  &__banner, &__review
    grid-column-start: 3
    grid-column-end: 11
    margin: 60px 0
    @include mq(tablet)
      grid-column-start: 1
      grid-column-end: 13


  &__text-content
    grid-column-start: 3
    grid-column-end: 11
    font-weight: 200
    font-size: 17px
    line-height: 28px
    margin: 40px 10px
    @include mq(phone)
      margin: 20px 10px
      grid-column-start: 1
      grid-column-end: 13

    &_full
      padding: 0 17%
      @include mq(phone)
        padding: 0

  &__text-content-title, &__text-content-subtitle
    white-space: pre-wrap
    padding-bottom: 10px
    grid-column-start: 1
    grid-column-end: 3
    font-weight: 400
    font-size: 21px
    line-height: 36px
    @include mq(phone)
      grid-column-start: unset
      grid-column-end: unset

  &__text-content-subtitle
    font-weight: 300

  &__text-content-body
    display: flex
    gap: 30px
    @include mq(phone)
      flex-direction: column
      margin: 0

  &__text-content-body-item
    white-space: pre-line
    width: 100%
    @include mq(phone)
      margin: 0
      padding-bottom: 1em

    &_right
      padding-left: calc(30% + 20px)
      @include mq(phone)
        padding: 0

    &_left
      padding-right: 30%
      @include mq(phone)
        padding: 0

  &__media-content
    margin: 10px

    img, video
      width: 100%
      height: 100%
      object-fit: cover

  &__technologies-list
    font-weight: 300


</style>

