<template>
    <div class="review">
        <div class="review__content">
            <div class="review-watermark">
                <svg width="37" height="27" viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.112 0.111999L11.616 13.36L20.112 26.68H9.24L0.456 13.36L9.24 0.111999H20.112ZM36.312 0.111999L27.816 13.36L36.312 26.68H25.44L16.656 13.36L25.44 0.111999H36.312Z" fill="#F2F2F2"/>
                </svg>

            </div>
            <p class="review__text">
                {{review.text}}
            </p>
            <p class="review__author">
                {{review.name}}
            </p>
            <p v-if="review.jop" class="review__position">
                {{review.jop}}
            </p>
            <p v-if="review.link_text" class="review__contact">
                <a
                   :href="review.link_url"
                   target="_blank"
                >
                    {{review.link_text}}
                </a>
            </p>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Review',
        components: {},
        props: {
            review: {
                type: Object,
                default: () => ({
                    name: '',
                    job: '',
                    text: '',
                    link_text: '',
                    link_url: ''
                })
            }
        },
    }
</script>


<style lang="sass">
    .review
        width: 100%
        color: $font-color

        &__content
            padding-bottom: 30px
        &__watermark

        &__text
            padding-top: 15px
            font-style: italic
            font-weight: 400
            font-size: 21px
            line-height: 36px
            letter-spacing: 0.01em

        &__author
            text-align: right
            padding-top: 25px
            font-weight: bold
            font-size: 21px
            line-height: 36px
            letter-spacing: 0.01em

        &__position
            padding-top: 20px
            font-weight: 500
            font-size: 21px
            line-height: 36px

        &__contact
            text-align: right
            padding-top: 20px
            font-weight: bold
            font-size: 17px
            line-height: 42px
            letter-spacing: 0.03em
            text-decoration: none



</style>
