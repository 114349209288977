<template>
    <Modal class="modal-thanks" v-if="show">
        <div class="modal-thanks__content">
            <button
                    class="modal-thanks__close"
                    @click="clearModalData"
            >
                <close/>
            </button>
            <div class="modal-thanks__title">
                <h1>{{modalData.title}}</h1>
            </div>
            <div class="modal-thanks__body">
                {{modalData.message}}
            </div>
        </div>
    </Modal>
</template>

<script>
    import Modal from "@/components/modal/Modal";
    import Close from "@/components/common/icons/Close";
    import { mapGetters, mapMutations } from "vuex";

    export default {
        name: 'Thanks',
        components: {
            Close,
            Modal
        },
        props: {
            show: {
                type: Boolean,
                default: () => false
            },

        },
        computed: {
            ...mapGetters([
                'modalData'
            ])
        },
        methods: {
            ...mapMutations([
                'clearModalData',
            ])
        }
    }

</script>

<style lang="sass">
    .modal-thanks
        @include fxc
        &__close
            all: unset
            &:hover
                cursor: pointer
            position: absolute
            top: 10px
            right: 10px

        &__content
            position: relative
            background-color: $panel-color
            padding: 10%
            border-radius: 15px
            z-index: 1


        &__title
            text-align: center
            padding: 20px 0
            @include mq(phone)
                font-size: 15px

        &__body
            text-align: center
            padding: 50px 0
            font-size: 30px
</style>