<template>
    <div class="contact">
        <div class="contact__content">
            <GetInTouch
                    :onFormSubmit="onSubmitForm"
                    class="contact__contact-form"
            />
        </div>
    </div>
</template>

<script>
    import GetInTouch from "@/components/home/GetInTouch";
    import { mapGetters } from "vuex";

    export default {
        name: 'Contact',
        components: {
            GetInTouch,
        },
        computed: {
            ...mapGetters([
                'contactPage',
                'translations',
                'device'
            ]),
        },
        methods: {
            onSubmitForm: function() {
                window.dataLayer.push({
                    'eventCategory': 'ContactForm',
                    'eventAction': 'Click',
                    'eventLabel': 'contact_form_contact_page_success',
                    'event': 'contact_form_contact_page_success'
                });
            }
        },
        mounted() {
            document.title = this.contactPage.meta_title;
            document.querySelector('meta[name="description"]').setAttribute("content", this.contactPage.meta_description);
        },
    }
</script>

<style lang="sass">

    .contact
        &__content
            padding-top: 40px
            @include mq(phone)
                padding-top: 10px

</style>
