<template>
  <div :class="{'team-page': true, 'team-page-mob': mobile}">
    <div class="team-page__title" v-if="settings.config">
      <span>{{settings.translations.team_page_title}}</span>
    </div>
    <div class="team-page__content">
      <div v-for="employee of team" :key="employee.id" class="team-page__employee">
        <div class="team-page__employee-img" v-if="employee.photo">
          <img :src="employee.photo[800]" alt="">
        </div>
        <div class="team-page__employee-text">
          <div class="team-page__employee-text-name">
            <span>{{employee.first_name}}</span><span> {{employee.last_name}}</span>
          </div>
          <div class="team-page__employee-text-position">
            <span>{{employee.job}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Team',
    components: {
    },
    props: {
        settings: {
            type: Object,
            default: () => {},
        },
        team: {
            type: Array,
            default: () => [],
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        categories: {
            type: Array,
            default: () => [],
        },
    },
    beforeCreate() {
        window.addEventListener("load", () => { this.$emit('uploaded') });
    },
    updated() {
        this.$emit('uploaded');
    },
}
</script>

<style lang="sass">
  .team-page
    &__title
      font-size: 28px
      line-height: 40px
      font-weight: bold
      max-width: 49%
      margin: 60px auto 0

    &__content
      display: flex
      flex-wrap: wrap
      margin-top: 90px

    &__employee
      width: 24%
      margin-right: 1.3%
      height: 26vw

      &:nth-child(4n)
        margin-right: 0

      &-img
        height: calc(100% - 60px)

        img
          width: 100%
          height: 100%
          object-fit: cover

      &-text
        text-align: start
        font-weight: bold
        margin-top: 5px

        &-name
          font-size: 14px
          line-height: 20px

        &-position
          font-size: 11px
          line-height: 16px

    &-mob
      .team-page
        &__title
          max-width: 100%

        &__employee
          width: 100%
          margin: 0
          height: 103vw
</style>
