<template>
    <div class="services">

        <div class="services__header-grid">
            <PageHeader
                    class="services__header"
                    :textSize="TEXT_SIZES.BIG"
                    :header="main.services.subtitle"
            />
        </div>

        <div class="services__content">
            <div class="services__list">
                <div class="services__list-column">
                    <Accordion v-for="item in main.services.list.slice(0, main.services.list.length / 2)"
                               v-bind:key="item.title"
                               :title="item.title"
                    >
                        <p>{{item.description}}</p>
                    </Accordion>
                </div>

                <div class="services__list-column">
                    <Accordion v-for="item in main.services.list.slice(main.services.list.length / 2)"
                               v-bind:key="item.title"
                               :title="item.title"
                    >
                        <p>{{item.description}}</p>
                    </Accordion>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
    import Accordion from "../common/Accordion";
    import PageHeader, { TEXT_SIZES } from '@/components/common/PageHeader'
    import { mapGetters } from "vuex";

    export default {
        name: 'Services',
        components: {
            Accordion,
            PageHeader
        },
        data: function() {
            return {
                TEXT_SIZES
            }
        },
        computed: {
            ...mapGetters([
                'main'
            ]),
        }
    }
</script>

<style lang="sass">
    .services
        position: relative
        width: 100%
        display: flex
        flex-direction: column
        margin-bottom: 100px
        @include mq(phone)
            margin-bottom: 50px

        &__header-grid
            @include grid(12, 1)

        &__header
            width: 100%
            font-size: 35px
            grid-column-start: 3
            grid-column-end: 11
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13

        &__content
            @include grid(12, 1)
            z-index: 1

        &__list
            grid-column-start: 3
            grid-column-end: 11
            @include mq(tablet)
                grid-column-start: 1
                grid-column-end: 13
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 0 10px
            @include mq(phone)
                grid-template-columns: 1fr

        &__list-column
            display: flex
            flex-direction: column

</style>
