<template>
    <router-link class="card"
                 :to="{name: 'project', params: {id: item.slug}}"
    >
        <img
                class="card__image"
                :src="item.main_page_image_square[800]" alt=""
        >
        <p class="card__text">{{item.name}}</p>
    </router-link>
</template>

<script>
    export default {
        name: 'Card',
        components: {},
        props: {
            item: {
                type: Object,
                default: () => ({
                    main_page_image_square: {
                        800: ""
                    },
                    name: 'test'
                })
            }
        },
        beforeCreate() {
            window.addEventListener("load", () => {
                this.$emit('uploaded')
            });
        },
        mounted() {
        },
        updated() {
            this.$emit('uploaded');
        },
    }
</script>

<style lang="sass">
    .card
        text-decoration: none
        &:hover
            cursor: pointer

        &__image
            width: 100%
            transition: all 0.3s ease
            &:hover
                transform: scale(1.02)

        &__text
            font-weight: 500
            font-size: 21px
            line-height: 36px
            color: #F2F2F2
</style>
