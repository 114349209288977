<template>
  <router-link :to="{name: 'home'}">
    <svg v-bind:width="sizes[this.size].width" height="50" viewBox="0 0 117 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35 30H40V32.5C40 36.65 36.65 40 32.5 40C28.35 40 25 36.65 25 32.5V5H30V15H35V20H30V32.5C30 33.9 31.1 35 32.5 35C33.9 35 35 33.9 35 32.5V30ZM12.5 0C8.35 0 5 3.35 5 7.5V15H0V20H5V40H10V20H15V40H20V15H10V7.5C10 6.1 11.1 5 12.5 5C13.9 5 15 6.1 15 7.5V10H20V7.5C20 3.35 16.65 0 12.5 0ZM116.5 27.5C116.5 34.95 110.45 41 103 41C95.55 41 89.5 34.95 89.5 27.5C89.5 20.05 95.55 14 103 14C110.45 14 116.5 20.05 116.5 27.5ZM111.5 27.5C111.5 22.8 107.7 19 103 19C98.3 19 94.5 22.8 94.5 27.5C94.5 32.2 98.3 36 103 36C107.7 36 111.5 32.2 111.5 27.5ZM69.5 27.5C69.5 28.35 69.4 29.2 69.25 30H47.85C48.9 33.5 52.15 36 56 36C58.35 36 60.45 35.05 62 33.5L65.55 37.05C63.1 39.5 59.75 41 56 41C48.55 41 42.5 34.95 42.5 27.5C42.5 20.05 48.55 14 56 14C63.45 14 69.5 20.05 69.5 27.5ZM64.15 25C63.1 21.5 59.85 19 56 19C52.15 19 48.95 21.5 47.85 25H64.15ZM82 40H87V15H82V40ZM72 40H77V5H72V40Z"
            fill="#F2F2F2"/>
    </svg>
  </router-link>
</template>

<script>

  const sizes = {
    small: {
      height: '30',
      width: '84'
    },
    medium: {
      height: '50',
      width: '140'
    }
  };

  export default {
    name: 'Logo',
    props: {
      size: {
        type: String,
        default: () => ('small')
      }
    },
    data: function () {
      return {
        sizes
      }
    },
  }

</script>