import ApplicationApi from "../../api/ApplicationApi";

// initial state
const initialState = {
    fields: {
        name: '',
        phone: '',
        email: '',
        message: '',
        agreement: false
    },
    errors: {}
};


const state = { ...initialState };

// getters
const getters = {
    fieldData: state => state.fields,
    errors: state => state.errors,
    formValid: state => Object.values(state.errors).filter(value => value === true).length === 0
};

// mutations
const mutations = {
    setField(state, field) {
        state.fields = {...state.fields, ...field};
    },
    setError(state, error) {
        state.errors = {...state.errors, ...error};
    }
};

// actions async tasks
const actions = {
    changeField({ commit }, { field, value }) {
        validateForm(field, value, commit);
        commit('setField', { [field]: value })
    },

    async postContact({ commit, state }) {
        try {
            const requestData = {
                ...state.fields,
                phone: state.fields.phone.replace(/[- ()+]/g, '')
            };
            const { data } = await ApplicationApi.postContact(requestData);
            commit('setModalData', data);
            commit('setField', initialState.fields);
            setTimeout(() => commit('setModalData', null), 5000);
            return data;
        } catch (e) {
            if (e.response){
                commit('setError', e.response.data)
            }
            console.error(e);
        }

    },
};

export default {
    // namespaced: true,
    state,
    getters,
    actions,
    mutations
}


function validateForm(field, value, commit) {
    let valid;
    switch (field) {
        case 'name':
            valid = value !== '';
            break;
        case 'phone':
            valid = value.length > 0 && value.indexOf('_') === -1;
            break;
        case 'email':
            valid = value.match(/^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i);
            break;
        default:
            valid = true
    }
    valid ? commit('setError', { [field]: false }) :
        commit('setError', { [field]: true });
}

