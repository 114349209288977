<template>
    <router-link class="banner"
                 :to="linkTo"
    >
        <p class="banner__text" v-html="text"></p>

        <div class="banner__bottom-header">
            <Button :onClick="() => {}">{{btnText}}</Button>
<!--            <div class="banner__bottom-header-border"></div>-->
        </div>

    </router-link>
</template>

<script>
    import Button from "./button";
    export default {
        name: 'Banner',
        components: {Button},
        props: {
            linkTo: {
                type: Object,
                default: () => ({
                    name: '',
                    params: {}
                })
            },
            text: {
                type: String,
                default: () => ('')
            },
            btnText: {
                type: String,
                default: () => ('')
            }
        },
    }
</script>


<style lang="sass">
    .banner
        @include fxc
        flex-direction: column
        justify-content: space-between
        min-height: 170px
        padding: 15px 20px
        background-color: $panel-color
        border-top: $border
        border-bottom: $border
        cursor: pointer
        position: relative
        text-decoration: none
        color: $font-color

        &__text
            font-weight: bold
            font-size: 17px
            line-height: 28px
            letter-spacing: 0.01em
            padding-bottom: 20px

        &__bottom-header
            @include fxc
            height: 40px
            flex-direction: column
            font-size: 21px
            line-height: 36px

        &__bottom-header-border
            width: 80%
            height: 2px
            background-color: $border-color


</style>
