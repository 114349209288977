<template>
  <div :class="{'headerMob-component': true, 'headerMob-component__open': menuOpened}">
    <div :class="{'headerMob-component__top': true,
    'headerMob-component__top-lit-opened':menuOpened}">
      <Logo v-show="!menuOpened"/>
      <div class="headerMob-component__top-btn" @click="menuOpened = true" v-show="!menuOpened">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 18H21V16H3V18V18ZM3 13H21V11H3V13V13ZM3 6V8H21V6H3V6Z"
                fill="#F2F2F2"/>
        </svg>
      </div>
    </div>
    <div :class="{'headerMob-component__top-lit': true, 'headerMob-component__top-lit-show': showLittleHeader}"
         :style="`top:${showLittleHeader}px`">
      <Logo v-show="!menuOpened"/>
      <div class="headerMob-component__top-btn" @click="menuOpened = true" v-show="!menuOpened">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 18H21V16H3V18V18ZM3 13H21V11H3V13V13ZM3 6V8H21V6H3V6Z"
                fill="#F2F2F2"/>
        </svg>
      </div>
    </div>
    <div class="headerMob-component__menu">

      <div class="headerMob-component__menu-top">
        <div class="headerMob-component__menu-top-logo">
          <Logo @click.native="menuOpened = false"
          />
        </div>
        <div class="headerMob-component__menu-top-cross" @click="menuOpened = false">
          <Close></Close>
        </div>
      </div>

      <div class="headerMob-component__menu-lang">
        <div :class="{'headerMob-component__menu-lang-item': true, 'active-lang': item.code === language}"
             v-for="item of languages"
             :key="item.code"
             @click="changeLanguage(item.code)">
          <span>{{item.name}}</span>
        </div>
      </div>


      <div class="headerMob-component__menu-links" v-if="translations.link_titles">
        <router-link class="headerMob-component__menu-links-item"
                     @click.native="menuOpened = false"
                     to="/projects">
          {{translations.link_titles.projects}}
        </router-link>
        <router-link class="headerMob-component__menu-links-item"
                     @click.native="menuOpened = false"
                     to="/about">
          {{translations.link_titles.about}}
        </router-link>
        <router-link class="headerMob-component__menu-links-item"
                     @click.native="menuOpened = false"
                     to="/contact">
          {{translations.link_titles.contacts}}
        </router-link>
        <router-link class="headerMob-component__menu-links-item"
                     @click.native="menuOpened = false"
                     :to="{name: 'project-detail', params: {id: 'dominos'}}"
        >
          {{main.dominos_link_button}}
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import Logo from '@/components/common/Logo.vue'
import Close from "@/components/common/icons/Close";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'HeaderMob',
  components: {
    Logo,
    Close
  },
  data() {
    return {
      menuOpened: false,
      littleHeader: false,
      showLittleHeader: false,
      transitionLittleHeader: false,
      prevScrollTop: 0,
    };
  },
  computed: {
    ...mapGetters([
      'translations',
      'languages',
      'language',
      'main'
    ])
  },
  watch: {
    menuOpened: function(status) {
      this.$emit('toggleMenu', status)
    }
  },
  created() {
    window.onscroll = this.scrollPage;
  },
  methods: {
    ...mapActions([
      'setLanguage'
    ]),

    changeLanguage(lang) {
      this.setLanguage(lang);
      this.menuOpened = false
    },

    scrollPage() {
      this.littleHeader = window.scrollY > 160;
      if (window.scrollY > this.prevScrollTop
              || window.scrollY < 160
              || window.scrollY > document.body.scrollHeight - 700) {
        this.prevScrollTop = window.scrollY;
        this.showLittleHeader = -60
      } else {
        this.showLittleHeader = 0;
        this.prevScrollTop = window.scrollY;
      }
    },
  }
}
</script>

<style scoped lang="sass">
.headerMob-component
  &__top
    @include fxc
    justify-content: space-between
    height: $header-mob-height
    z-index: 5
    background-color: $bgc

    &-lit
      display: flex
      justify-content: space-between
      align-items: center
      top: -60px
      position: fixed
      width: calc(100% - 35px)
      height: 60px
      background-color: $bgc
      z-index: 4
      transition: all .1s

      &-opened
        z-index: 1

      &-show
        top: 0

        &-transition
          top: -60px

    &-btn
      position: relative
      top: 7px

  &__menu
    height: 100%
    width: 100%
    position: fixed
    top: 0
    z-index: 5
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: space-between
    color: #fff
    left: -100%
    background-color: #222222
    padding: 0
    text-align: start
    transition: left .3s

    &-top
      display: flex
      justify-content: space-between
      height: 130px
      padding: 0 20px
      align-items: center

    &-lang
      padding-left: 30px
      font-size: 17px
      font-weight: bold

      &-item
        @include transition(color)
        color: $darken-font-color
        padding-bottom: 30px

      .active-lang
        color: $font-color

    &-links
      display: flex
      flex-direction: column
      font-weight: bold
      box-sizing: border-box
      padding-bottom: 40px

      &-item
        box-sizing: border-box
        min-height: 80px
        width: 100%
        display: flex
        align-items: center
        margin: -1px 0
        padding: 0 5px 0  20px
        border-top: $border
        border-bottom: $border
        font-size: 28px
        line-height: 40px
        letter-spacing: 0.03em
        color: $font-color
        text-decoration: none

  &__open
    .headerMob-component__menu
      left: 0

</style>
